html {
    font-size: 18px;
}

body {
    @apply text-justify text-gray-800;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    padding: 0;
    margin: 0 auto;
    line-height: 1.25;
}

::selection {
    @apply bg-turquoise-500 text-turquoise-100;
}

h1, h2, h3, h4, h5, h6 {
    @apply mb-4 font-bold;
    font-family: "Domine", serif;
}

h1 {
    @apply text-5xl;
}

h2 {
    @apply text-4xl;
}

h3 {
    @apply mt-4 text-3xl;
}

h4 {
    @apply mt-4 text-2xl;
}

h5 {
    @apply text-xl;
}

h6 {
    @apply text-lg;
}

p + p {
    @apply mt-3;
}

a {
    @apply text-gray-800;
    &:hover {
        @apply underline text-turquoise-700;
    }
}

address {
    @apply not-italic;

    .country {
        @apply hidden;
    }
}

h2.decorate {
    @apply py-6 text-center uppercase;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 20px;
}

h5.decorate {
    @apply py-4 text-center uppercase;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 10px;
}

@supports (display: grid) {
    h2.decorate:before,
    h2.decorate:after,
    h5.decorate:before,
    h5.decorate:after {
        content: "";
        align-self: center;
        border-top: 1px solid #efefef;
    }
}

svg {
    width: 100%;
    height: 100%;
}

hr {
    @apply my-4 border-b-2 border-gray-400;
}

nav button:focus {
    @apply outline-none;
}

label:not(.style-reset):not(.el-checkbox):not(.el-radio):not(.el-checkbox-button):not(.el-radio-button) {
    @apply block mb-2 text-sm font-bold tracking-wider text-gray-800 uppercase;

    & > input[type="checkbox"],
    & > input[type="radio"] {
        & + span {
            @apply ml-1 font-normal text-left;
        }
    }
}

::placeholder {
    @apply text-xs text-gray-600;
}

label {
    @apply cursor-pointer;

    &.has-error {
        @apply text-watermelon-500;

        & + select, & + input, & + textarea {
            @apply border-watermelon-500 text-watermelon-500;
        }
    }
}

#footer {
    @apply text-white;

    a {
        @apply text-white;
        &:hover {
            @apply text-gray-200;
        }
    }
}

.page-title, .section-title {
    @apply w-full pb-4 mb-4 text-left border-b border-gray-400;
}

.section-title {
    @apply mt-4;
}

.table-expanded {
    th, td {
        @apply p-4 text-xs;
    }
}

@import "buttons";

.font-cursive {
    font-family: "Aladin", cursive;
}
