.button {
    @apply inline-block p-3 text-base text-center no-underline uppercase border rounded shadow;
    font-family: "Domine", serif;

    &:hover {
        @apply underline;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
        @apply no-underline cursor-not-allowed;

        &:hover {
            @apply no-underline;
        }
    }

    &:focus, &:active {
        @apply outline-none;
    }
}

@responsive {
    .button {
        &.button-lg {
            @apply p-4 text-lg;
        }

        &.button-base {
            @apply p-3 text-base;
        }

        &.button-sm {
            @apply p-2 text-sm;
        }

        &.button-xs {
            @apply p-1 text-xs;
        }
    }
}
